import axios from 'axios';
import {promisify} from 'util'
var {apiUrl, loginUrl} = require('../config').config
    
export const post = promisify(postFn)
async function postFn (module, data, cb){
    try{
        let res = await axios.post(`${apiUrl}/post/${module}`, data)
        if (res.data.status == 0) return cb(res.data)
        else return cb(null, res.data)
    }catch(e){
        return cb(e)
    }
}

export const put = promisify(putFn)
async function putFn (module, data, id, cb){
    try{
        let res = await axios.put(`${apiUrl}/put/${module}?id=${id}`, data)
        if (res.data.status == 0) return cb(res.data)
        else return cb(null, res.data)
    }catch(e){
        return cb(e)
    }
}

export const get = promisify(getFn)
async function getFn (module, options, cb) {
    try{
        console.log(module, 'module')
        // options.isAdmin = true
        let res = await axios.get(`${apiUrl}/get/${module}`, {params: options} )
        if(res.data.status == 0) { return cb(res.data) }
        else{ return cb(null, res.data) }
    }catch(e){
        return cb(e)
    }
}

export const deleteData = promisify(deleteFn)
async function deleteFn (module, options, cb){
    try{
        console.log(options, 'options')
        let res = await axios.delete(`${apiUrl}/delete/${module}?id=${options.id}` )
        return cb(null, res.data)
    }catch(e){
        return cb(e)
    }
}

export const getById = promisify(getByDataById)
async function getByDataById (module, id, cb){
    try{
        // console.log(options, 'options')/
        let res = await axios.get(`${apiUrl}/get/${module}?id=${id}` )
        return cb(null, res.data)
    }catch(e){
        return cb(e)
    }
}

export const postApi = async (url,data, options) => {
    return new Promise(async (resolve, reject) => {
        try{
            let res = await axios.post(`${apiUrl}/${url}`, data, {options})
            if(!res) throw Error('Server error')
            resolve(res.data)
        }catch(e){
            console.log(e, 'e')
            reject(e)
        }
    })
}

export const commonApi = async (method, url ,data, options) => {
    try{
        // change to apiUrl when this ready
        let res = await axios[method](`${loginUrl}/${url}`, data, {options})
        if(res.data.status != 1) throw Error(res.data.message)
        return res.data
    }catch(e){
        console.log(e, 'e')
        throw e
    }
}

export const searchApi = async (url, options) => {
    return new Promise(async (resolve, reject) => {
        try{
            let res = await axios.get(`${apiUrl}/search/${url}`, {options})
            if(!res) throw Error('Server error')
            resolve(res.data)
        }catch(e){
            console.log(e, 'e')
            reject(e)
        }
    })
}

export const commonCrud = async (method, callType, url, options) => {
    try{
        console.log(method, callType, url, options)
        let res = await axios[method](`${apiUrl}/${callType}/${url}`,  {params: options})
        if(!res) throw Error('Server error')
        return res.data
    }catch(e){
        console.log(e, 'e')
        throw e
    }
}

export const uploadFile = (file, folder) => {
    return new Promise(async (resolve, reject) => {
        try{
            let folderName = 'iroomz'
            if(folder) folderName = folder 
            let data = new FormData()
            data.append('file', file)
            let uploaded = await axios.post(`${apiUrl}/upload?folder=${folderName}`, data, {})
            if(uploaded.data.status !=1) throw Error('Upload error')
            resolve(uploaded.data)
        }catch(e){
            console.log(e, 'e')
            reject(e)
        }
    })
}