import React from 'react';
// import './Modal.css'; // Import CSS file for styling

function Modal({ isOpen, onClose, onDelete }) {
  if (!isOpen) return null;

  return (
    <div className="newModal">
      <div className="modalContent">
        <span className="closeNew" onClick={onClose}>&times;</span>
        <p>Are you sure you want to delete?</p>
        <div className="button-container">
          <button className="cancel-btn" onClick={onClose}>Cancel</button>
          <button className="delete-btn" onClick={onDelete}>Delete</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
