let apiUrl, baseUrl, loginUrl, env; 

// let secCode = process.env.REACT_APP_SECRET_CODE ? process.env.REACT_APP_SECRET_CODE.trim() : null
env = process.env.NODE_ENV == 'development' ? 'development' : 'staging'

apiUrl ='https://api-hc.healthonify.com/'
loginUrl = 'https://api.healthonify.com'

let project = "Heal_Lab_admin"

export const config = {
	apiUrl, baseUrl, project, loginUrl
}
