import React, { useEffect, useState } from "react";
import { component } from "../data";
import { get, getById, post, put } from "../../../apiHandler/commons";
import Navbarside from "../../../common/Navbarside";
import Footer from "../../../common/footer";
import { useParams } from "react-router-dom";
import Loader from "../../../common/Loader";


const Labtestdetails = () => {
  const [name, setName] = useState("");
  const [labEmail, setLabEmail] = useState("");
  const [labMobileNo, setLabMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [homeServicePercent, setHomeServicePercent] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [hplCommission, sethplCommission] = useState("");
  const [platformCostPercent, setPlatformCostPercent] = useState("");
  const [gstPercent, setGstPercent] = useState("");
  const [labtestname, setLabtestname] = useState("");
  const [isloading, setIsLoading] = useState(false);

  const { id } = useParams();
  localStorage.setItem("labId", id);

  const getLabDetails = () => {
    // Get Lab Call
    setIsLoading(true);
    getById(component.module, id)
      .then((res) => {
        setIsLoading(false);
        setName(res?.data[0]?.name);
        setLabEmail(res?.data[0]?.labEmail);
        setLabMobileNo(res?.data[0]?.labMobileNo);
        setAddress(res?.data[0]?.address);
        sethplCommission(res?.data[0]?.hplCommission);
        setHomeServicePercent(res?.data[0]?.homeServicePercent);
        setserviceType(res?.data[0]?.serviceType[0]);
        setGstPercent(res?.data[0]?.gstPercent);
        setPlatformCostPercent(res?.data[0]?.platformCostPercent);
        setLabtestname(res?.data[0]?.labTestCategoryId[0].name);
      })
      .catch((e) => {
        alert("Something went wrong");
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getLabDetails();
  }, []);

  return (
    <div>
      <Navbarside />
      {isloading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Details</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive ">
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Lab Name:</p>
                              <span>{name}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Lab Email:</p>
                              <span>{labEmail}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Contect Number:</p>
                              <span>{labMobileNo}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Service Type:</p>
                              <span>{serviceType}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Home Service Charges:</p>
                              <span>{homeServicePercent} % </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>HPL Commission:</p>
                              <span>{hplCommission} %</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p> Platform Cost Percent:</p>
                              <span> {platformCostPercent} %</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>GST Percent:</p>
                              <span>{gstPercent} %</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="text d-flex justify-content-between">
                              <p style={{ color: "gray" }}>
                                <b>Test Provided:</b>
                              </p>
                              <span>{labtestname}</span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between text">
                              <p>Address:</p>
                              <span>{address}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Labtestdetails;
