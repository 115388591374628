import './App.css';
import RoutesHandle from './Routes';

function App() {
  return (
    <div className="App">
      {/* <MyLabs/> */}
      <RoutesHandle/>
    </div>
  );
}

export default App;
