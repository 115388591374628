import React from 'react';

// Material components
// import { Grid, Typography } from '@material-ui/core';
import { Grid, Typography } from '@mui/material';
import Navbarside from '../../common/Navbarside';


// Component styles


const NotFound = (props)=> {
  console.log("notFounds")
  const styles = theme => ({
    root: {
      padding: theme.spacing.unit * 4
    },
    content: {
      marginTop: '150px',
      textAlign: 'center'
    },
    image: {
      display: 'inline-block',
      marginTop: '50px',
      maxWidth: '100%',
      width: '554px'
    }
  });


    return (
      <div className={styles.root}>
        <Navbarside/>
        <Grid
          container
          justify="center"
          spacing={4}
        >
          <Grid
            item
            lg={6}
            xs={12}
          >
            <div className={styles.content}>
              <Typography variant="h1">
                404: The page you are looking for isn’t here
              </Typography>
              <Typography variant="subtitle2">
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation
              </Typography>
              <img
                alt="Under development"
                className={styles.image}
                src="/images/not_found.png"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
// }


export default NotFound;
