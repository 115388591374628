import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ProtectedRoutes from './view/ProtectedRoutes';
import PublicRoutes from './view/PublicRoutes';
import NotFound from './view/NotFound/NotFound';
import MyLabs from './view/MyLabs/List';
import AddLabdetails from './view/MyLabs/Create';
import Login from './common/Login';
import Dashboard from './view/DashBoard';
import UpdateLabDetails from './view/MyLabs/Update';
import Profile from './view/Profile/Profile';
import EditProfile from './view/Profile/EditProfile';
import LabDetails from './view/MyLabs/LabDetails'
// import MyLabs from './view/MyLabs/Create/MyLabs';

const RoutesHandle = ()=>{
    const navigate = useNavigate()

    useEffect(()=>{
const url = window.location.pathname
if(url == '/'){
navigate('/home')
}
    },[])
    return (
<Routes>
        <Route path="login" element={<PublicRoutes />}>
          <Route exact path="/login" element={<Login />} />
        </Route>
    <Route path='/'  element={<ProtectedRoutes />}>
        <Route path='/home' element ={<Dashboard/>}/>
        <Route path='MyLabs' element = {<MyLabs/>} />
        {/* <Route path='/login' element = {<Login/>}/> */}
        <Route path='/MyLabs/AddLabdetails' element = {<AddLabdetails/>}/>
        <Route path='/MyLabs/EditLab/:id' element = {<UpdateLabDetails/>}/>
        <Route path='/profile' element = {<Profile/>}/>
        <Route path='/EditProfile' element = {<EditProfile/>}/>
        <Route exact path='/MyLabs/LabDetails/:id' element={<LabDetails />} />
        <Route path='*' element = {<NotFound/>}/>

    </Route>
</Routes>
      );
}

export default RoutesHandle