import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import Navbarside from "../../common/Navbarside";
import { Link } from "react-router-dom";
import { get, getById, post, put } from "../../apiHandler/commons";
import { component } from "./data";


import Footer from "../../common/footer";
import Loader from "../../common/Loader";
// import { userDetails } from "../store/slices/auth";

const Profile = () => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false)

//   const dispatch = useDispatch();

  React.useEffect(() => {
    let userId = JSON.parse(localStorage.getItem("user"))?.data?.id
    setLoading(true)
    get(component.module, { id: userId }).then((data)=>{
        setLoading(false)
        setDetails(data.data[0]);

    }).catch(({ message }) => {
        alert(message);
      });

  }, []);

  return (
    <div>
      <Navbarside />
      {loading && <Loader/>}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card mb-4">
                    <div className="card-header">
                      <div className="card-title-wrap bar-primary">
                        <h4
                          className="card-title"
                          id="basic-layout-form-center"
                        >
                          Personal Information &nbsp;
                          <Link
                            to="/EditProfile"
                            className="btn btn-primary wd-100"
                          >
                            Edit
                          </Link>
                        </h4>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card-block">
                        <div className="align-self-center halfway-fab text-center">
                          <a className="profile-image">
                            <img
                              alt=""
                              src={details?.imageUrl}
                              className="rounded-circle img-border gradient-summer "
                              style={{ height: "120px", width: "120px" }}
                            />
                          </a>
                        </div>

                        <div className="text-center">
                          <span className="font-medium-2 text-uppercase">
                            {" "}
                            {details?.firstName} {details?.lastName}
                          </span>
                          {/* <p className="grey font-small-2">Facebook Founder</p> */}
                        </div>
                        <br />
                        <br />
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <span className="text-bold-500 primary">
                              <i className="icon-user font-small-3"></i> Name:{" "}
                              {details?.firstName} {details?.lastName}
                            </span>
                          </div>

                          <div className="col-12 col-md-6">
                            <span className="text-bold-500 primary">
                              <i className="icon-envelope-open font-small-3"></i>{" "}
                              Email: {details?.email}
                            </span>
                          </div>

                          <div
                            className="col-12 col-md-6"
                            style={{ marginTop: "20px" }}
                          >
                            <span className="text-bold-500 primary">
                              <i className="icon-screen-smartphone font-small-3"></i>{" "}
                              Phone: {details?.mobileNo}
                            </span>
                          </div>
                          {/* <div
                            className="col-12 col-md-6"
                            style={{ marginTop: "20px" }}
                          >
                            <span className="text-bold-500 primary">
                              <i className="icon-present font-small-3"></i>{" "}
                              Birthday: June 10th, 1988
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Profile;
