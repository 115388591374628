// import Navbarside from '../../common/Navbarside'
// import Footer from '../../common/footer'

// const Dashboard =() =>{
// return (
//     <>
//     <Navbarside/>
//     <Footer/>
//     </>
// )
// }


// export default Dashboard

import React, { useState } from "react";
import Navbarside from "../../common/Navbarside";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/footer";
import { get } from "../../apiHandler/commons";
// import { useDispatch } from "react-redux";
// import { userDetails } from "./../store/slices/auth";
// import { expiredSubscription } from "./../store/slices/client";
// import { getlabtestresultDatewise } from "../store/slices/lab";
function Home() {
  const navigate = useNavigate("");
//   const dispatch = useDispatch();
  const [enquiryCount, setEnquiryCount] = useState(0);
  let data = JSON.parse(localStorage.getItem("user"));
  let role = data?.data?.roles[0];
  let fname = data?.data?.firstName;
  let lname = data?.data?.lastName;

  const [fdate, setfDate] = useState("");
  const [tdate, settDate] = useState("");
  const [totlab, setTotLab] = useState("");
  const [loading, setLoading] = useState(false)


  const myDatas = {
    fromDate: fdate,
    toDate: tdate,
  };

  let fromDate = new Date().toISOString().slice(0, 10);
  let toDate = new Date().toISOString().slice(0, 10);
  localStorage.setItem("fromDate", fromDate);
  localStorage.setItem("toDate", toDate);
  React.useEffect(() => {
    let id = JSON.parse(localStorage.getItem("user"))?.data?.id;
    console.log("id",id)
    let fdate= localStorage.getItem("fromDate");
    let tdate = localStorage.getItem("toDate");
    // const option = {}
    get('labTest',{vendorId:id,fromDate:fdate,toDate:tdate}).then((data) => {
        setTotLab(data?.dataCount);
        console.log("testing",data)
      })
      .catch(({ message }) => {
        // alert(message);
      });
      get('user', { id: id }).then((data)=>{
        setLoading(false)
        setDetails(data?.data[0]);

    }).catch(({ message }) => {
        alert(message);
      });
    //   get('user', { id: id }).then((data)=>{
    //     setLoading(false)
    //     setDetails(data?.data[0]);

    // }).catch(({ message }) => {
    //     alert(message);
    //   });
  }, []);

  async function getoDateEnquiry(e) {
    e.preventDefault();
    navigate("/Enquiry/", { state: myDatas });
  }
  const [details, setDetails] = useState("");
  const [userCount, setuserCount] = useState("");
  const [topExpertise, settopExpertise] = useState("");
//   React.useEffect(() => {
//     dispatch(userDetails())
//       .unwrap()
//       .then((data) => {
//         setDetails(data?.user.data[0]);
//         settopExpertise(data?.user.data[0].topExpertise.name);
//       })
//       .catch(({ message }) => {
//         // alert(message);
//       });
//   }, [dispatch]);

//   React.useEffect(() => {
//     dispatch(expiredSubscription())
//       .unwrap()
//       .then((data) => {
//         setuserCount(data?.user.data?.clientsCount);
//       })
//       .catch(({ message }) => {
//         // alert(message);
//       });
//   }, [dispatch]);
  return (
    <div>
      <Navbarside />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-6 col-12">
                  <div className="card bg-white">
                    <div className="card-body">
                      <div className="card-block pt-2 pb-0">
                        <div className="media">
                          <div className="media-body white text-left">
                            <h4 className="font-medium-5 card-title mb-0">
                              Welcome {fname} {lname}!
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div
                        id="Widget-line-chart"
                        className="height-70 lineChartWidget WidgetlineChart mb-2"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              {role === "ROLE_SUPERADMIN" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form onSubmit={getoDateEnquiry}>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    135 Enquiry
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={fdate}
                                  onChange={(e) => setfDate(e.target.value)}
                                />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={tdate}
                                  onChange={(e) => settDate(e.target.value)}
                                />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-3">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  135 Open Enquiry
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <p>From</p>
                              <input className="form-control" type="date" />
                            </div>
                            <div className="col-md-3">
                              <p>To</p>
                              <input className="form-control" type="date" />
                            </div>
                            <div className="col-md-3">
                              <p>&nbsp;</p>
                              <button type="submit" className="btn btn-success">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Appointments
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Today's Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-poll fa-edit-icon warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-70 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {userCount}
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Today's Birthday
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                App Installs
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {topExpertise === "Health Care" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    5 Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {topExpertise === "Dietitian" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    5 Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Consultations
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Active Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Inactive Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {topExpertise === "Physiotherapy" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    5 Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Consultations
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Active Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Inactive Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {topExpertise === "Fitness" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    5 Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Consultations
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Sessions
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Active Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="row pb-2">
                            <div className="col-md-6">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  15 Inactive Clients
                                </h4>{" "}
                                <span className="grey darken-1">Today</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <button type="submit" className="btn btn-success">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Active Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Expired Subscription
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {role === "ROLE_LABVENDOR" && (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <form>
                            <div className="row pb-2">
                              <div className="col-md-3">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {totlab} Appointments
                                  </h4>{" "}
                                  <span className="grey darken-1">Today</span>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <p>From</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>To</p>
                                <input className="form-control" type="date" />
                              </div>
                              <div className="col-md-3">
                                <p>&nbsp;</p>
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Bookings
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {role === "ROLE_FITNESSCENTERADMIN" && (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                This Month Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">Total Paid</span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="card bg-white">
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                0
                              </h4>
                              <span className="grey darken-1">
                                Total Bookings
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
