import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { FcSettings } from "react-icons/fc";
import {
  FaBuromobelexperte,
} from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";
import { BiTestTube } from "react-icons/bi";
import {
  MdOutlineLogout,

} from "react-icons/md";


import {
  GiTestTubes,
  GiTransparentTubes,
} from "react-icons/gi";
import { RiHomeGearFill } from "react-icons/ri";
import { Link, useNavigate, NavLink } from "react-router-dom";
import logo from "../img/healthonify-logo.png";
// import { userDetails } from "./../store/slices/auth";
const Navbarside = () => {
  // const dispatch = useDispatch();
  const [role, setRole] = useState('ROLE_SUPERADMIN"')

  const open = () => {
    document.getElementById("side").classList.toggle("show");
  };

  const drop = () => {
    document.getElementById("usermenu").classList.toggle("showuser");
  };

  // let data = JSON.parse(localStorage.getItem("user"));
  // let role = data.data.roles[0];

  const navigate = useNavigate();



  function signOut(e) {
    localStorage.clear();
    navigate("/login");
  }
  // const [details, setDetails] = useState("");
  // const [topExpertise, settopExpertise] = useState("");
  // React.useEffect(() => {
  //   dispatch(userDetails())
  //     .unwrap()
  //     .then((data) => {
  //       setDetails(data.user.data[0]);
  //       settopExpertise(data.user.data[0].topExpertise.name);
  //     })
  //     .catch(({ message }) => {
  //       // alert(message);
  //     });
  // }, [dispatch]);

  return (
    <div>
      <nav className="top_nav">
        <FcSettings className="setting animate-rotate " onClick={open} />
        <IoPersonCircle className="user-icon" onClick={drop} />
      </nav>

      <nav className="sidebar" id="side">
        <NavLink to="/">
          <div className="d-flex justify-content-center logo-section">
            <img src={logo} alt="healthonify" />
          </div>
        </NavLink>
        <ul className="nav-lists pt-1">
          <li className="nav_items top_item">
            <NavLink to="/home" className="menu-title" activeClassName="active">
              <RiHomeGearFill className="nav_icons" />
              Dashboard
            </NavLink>
          </li>
          {/* super admin  menu start*/}
          {/* {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <a href="/Enquiry" className="menu-title">
                <i className="far fa-comments fa-edit-icon"></i>&nbsp; General
                Enquiry
              </a>
            </li>
          )} */}

          {/*Lab vendor*/}
          {role === "ROLE_LABVENDOR" || true && (
            <li className="nav_items">
              <NavLink to={"/MyLabs"} className="menu-title">
                <GiTestTubes className="nav_icons" />
                My Labs
              </NavLink>
            </li>
          )}
          {role === "ROLE_LABVENDOR" || true&&  (
            <li className="nav_items">
              <NavLink to="/Labtest" className="menu-title">
                <GiTransparentTubes className="nav_icons" />
                Lab Test
              </NavLink>
            </li>
          )}
          {role === "ROLE_LABVENDOR"  || true&&  (
            <li className="nav_items">
              <NavLink to="/LabVendorRevenue" className="menu-title">
                <BiTestTube className="nav_icons" />
                Revenue
              </NavLink>
            </li>
          )}
          {/* fitness center plan ends */}
          <li className="nav_items">
            <Link to="/Login" onClick={signOut} className="menu-title ">
              <MdOutlineLogout className="nav_icons" />
              Logout
            </Link>
          </li>
        </ul>
      </nav>
      <div className="drop-user" id="usermenu">
        <ul className="user-p">
          <li className="user-text">
            <NavLink to="/profile">
              <FaBuromobelexperte className="user-icon-style-mod" />
              My Profile
            </NavLink>
          </li>

          <li className="user-text">
            <Link to="/Login" onClick={signOut}>
              <MdOutlineLogout className="user-icon-style-mod" />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbarside;
