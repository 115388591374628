import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbarside from "../../../common/Navbarside";
import { component } from "../data";
import { confirmAlert } from "react-confirm-alert";
// import { getVendorLabs } from "../store/slices/lab";
import Footer from "../../../common/footer";
import { deleteData, get } from "../../../apiHandler/commons";
import Loader from "../../../common/Loader";
import Modal from "../../../common/Modal";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../../common/Pagination";

export default function MyLabs() {
  const [labDetail, setlabDetail] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("user"))?.id;
  React.useEffect(() => {
    getLabs();
  }, []);

  const getLabs = (page = 0) => {
    setIsloading(true);
    get(component.module, { page: page, limit: 10, vendorId: userId })
      .then((res) => {
        setpage(page);
        setTotalCount(res.totalCount);
        setIsloading(false);
        setlabDetail(res?.data);
      })
      .catch((e) => {
        alert("Something went wrong");
      });
  };

  const onDelete = (id) => {
    setDeleteId(id);
    setisOpen(!isOpen);
  };

  const deleteHandler = () => {
    let option = { id: deleteId };
    setIsloading(true);
    deleteData(component.module, option)
      .then((res) => {
        setIsloading(false);
        setisOpen(false);
        if (res) {
          getLabs();
        }
      })
      .catch(() => {
        setIsloading(false);
        setisOpen(false);

        alert("Something went wrong");
      });
  };

  // Filter data based on search

  const filterOnchange = (searchItem) => {
    const filtered = labDetail.filter(
      (item) =>
        item.name.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.address.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.labEmail.toLowerCase().includes(searchItem.toLowerCase())
      // ||
      // item.labMobileNo.includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <div>
      <Navbarside />
      {isloading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        {/* <Modal isOpen={isOpen} onClose={()=>{setisOpen(false)}} onDelete= {()=>{console.log("ogk")}}/> */}

        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <Modal
                      isOpen={isOpen}
                      onClose={() => setisOpen(false)}
                      onDelete={deleteHandler}
                    />
                    <div className="card-header  ">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab List</h4>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to="/MyLabs/AddLabdetails"
                          className="btn btn-primary col-white mt-2"
                          style={{
                            height: "25px",
                            padding: "0 10px",
                          }}
                        >
                          Add New Lab
                        </Link>
                      </div>
                      <div className="d-inline-flex p-2 align-items-center rounded  border border-black">
                        <a className="d-inline-block text-black" href="#">
                          <FiSearch size={23} color={"black"} />
                        </a>
                        <input
                          className="px-3 ml-n3 bg-transparent text-black border-0 outline-none"
                          type="text"
                          placeholder="Search..."
                          // value={searchTerm}
                          onChange={(e) => filterOnchange(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th style={{ width: "150px" }}>Lab Name</th>
                              <th>Address</th>
                              <th>Email</th>
                              <th>Mobile Number</th>
                              <th>Revenue</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(filteredData?.length > 0
                              ? filteredData
                              : labDetail
                            )?.map((LabVal, index) => (
                              <tr>
                                <td>
                                  <Link
                                    to={`/MyLabs/LabDetails/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                  >
                                    {LabVal.name}
                                  </Link>
                                </td>
                                <td>{LabVal.address}</td>
                                <td>{LabVal.labEmail}</td>
                                <td>{LabVal.labMobileNo}</td>

                                {/* <td>
                                  {LabVal.status === "approvalPending" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approval Pending
                                    </button>
                                  )}
                                  {LabVal.status === "rejected" && (
                                    <button
                                      className="btn btn-danger"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Rejected
                                    </button>
                                  )}
                                  {LabVal.status === "approved" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  )}
                                </td> */}
                                <td>
                                  {
                                    <button
                                      disabled={LabVal.status !== "approved"}
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate(
                                          `/LabRevenue/${
                                            LabVal._id ? LabVal._id : null
                                          }`
                                        );
                                      }}
                                    >
                                      View
                                    </button>
                                  }
                                </td>
                                <td>
                                  <Link
                                    to={`/MyLabs/EditLab/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    Edit
                                  </Link>{" "}
                                  <Link
                                    to={`/MyLabs/LabDetails/${
                                      LabVal._id ? LabVal._id : null
                                    }`}
                                    className="btn btn-primary"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    Details
                                  </Link>{" "}
                                  <button
                                    onClick={() => {
                                      console.log("value", LabVal);
                                      onDelete(LabVal?._id);
                                    }}
                                    className="btn btn-warning"
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          {/* <table id="example" className="display"></table> */}
                          <div className="PaginationContainer">
                            <span className="total-elements">
                              Showing {parseInt(page) * 10 + 1} to{" "}
                              {parseInt(page) * 10 + 10} of {totalCount} entries
                            </span>
                            <Pagination
                              currentPage={parseInt(page) + 1}
                              totalCount={totalCount}
                              itemsPerPage={10}
                              onPageChange={(page) => {
                                getLabs(parseInt(page) - 1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
